import React, { useState, useEffect } from 'react';

import { get } from '../services/api';
import Loading from './Loading/Loading';
import PowerfulTable from './PowerfulTable/PowerfulTable';

const PupilsList = (props) => {

  const [ pupils, setPupils ] = useState([]);
  const [ filteredPupils, setFilteredPupils ] = useState([]);
  const [ filter, setFilter ] = useState('');
  const [ topics, setTopics ] = useState([]);

  useEffect(() => {
    fetchPupils();
  }, [props.pupils]);

  const fetchPupils = async () => {
    const pupils = await get('/admin/pupils?limit=2000');
    setPupils(pupils);
    setFilteredPupils(pupils);
    setFilter('');
  }

  const filterPupils = () => {
    const s = filter.toLowerCase()
    const filteredPupils = pupils.filter((p) => (
      p._id.toLowerCase().includes(s)
      || p.firstname.toLowerCase().includes(s)
      || p.lastname.toLowerCase().includes(s)
      || (p.number?.toLowerCase().includes(s))
      || (new Date(p.signUp?.timestamp || p.createdAt)).toLocaleString().toLowerCase().includes(s))
    )
    setFilteredPupils(filteredPupils);
  }

  useEffect(() => {
    filterPupils();
  }, [filter]); // eslint-disable-line

  if (!pupils?.length) return <Loading />

  return (
    <div style={{ marginLeft: 20, width: '100%', height: '100%' }}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>Elèves ({pupils.length})</h2>
        <div style={{marginRight: 50}}>
          <input
            placeholder='Rechercher...'
            type='text'
            value={filter}
            onChange={({ target: { value }}) => setFilter(value)}
            style={{ paddingLeft: 10, width: 180, height: 30, borderRadius: 30, boxShadow: 'none', borderStyle: 'hidden', backgroundColor: '#eee', outline: 'none' }}
          />
        </div>
      </div>

      <PowerfulTable
        data={filteredPupils}
        types={{
          _id: { name: 'email' },
          firstname: { name: 'prénom' },
          lastname: { name: 'nom' },
          number: { name: 'téléphone' },
          zipcode: { name: 'code postal' },
          signup: { name: 'inscription' },
          subscription: { name: 'abonnement' },
          pass: { name: 'pass (fin)' },
        }}
        redirect={'pupils'}
        redirectKey={'_id'}
      />
    </div>
  );
};

export default PupilsList;
