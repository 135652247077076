import colors from './colors'

const getColor = (status) => {
  switch(status) {
    case 'cancelled':
      return colors.error
    case 'confirmed':
      return colors.button_blue
    case 'ordered':
      return colors.orange
    case 'done':
      return colors.validated
    case 'assigned':
      return colors.orange
    default:
      return 'black'
  }
}

export {
  getColor,
}
