import React, { useCallback, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';

import Header from './components/Header/Header';

import Login from './components/Login';
import Home from './components/Home';
import Order from './components/Order';
import OrdersList from './components/OrdersList';
import Teacher from './components/Teacher';
import TeachersList from './components/TeachersList';
import Pupil from './components/Pupil';
import PupilsList from './components/PupilsList';
import PassesList from './components/PassesList';

import AuthContext from './contexts/AuthContext';
import { setAuthHeader, removeAuthHeader } from './services/api';

import styles from './App.module.css';
import SubscriptionsList from './components/SubscriptionsList';

const App = (props) => {

  const saveHeader = (authHeader) => {
    setAuth({
      ...auth,
      authHeader,
    });
    localStorage.setItem('AUTH_HEADER', authHeader);
    setAuthHeader(authHeader);
  };

  const signout = () => {
    setAuth({
      ...auth,
      authHeader: null,
    });
    localStorage.removeItem('AUTH_HEADER');
    removeAuthHeader();
    window.location.href = '/login';
  };

  const [ auth, setAuth ] = useState({
    authHeader: localStorage.getItem('AUTH_HEADER'),
    saveHeader: saveHeader,
    signout: signout,
  });

  const handleSignout = useCallback(signout, []);

  return (
    <AuthContext.Provider value={auth}>
      <Router>
        <div className={styles.appContainer}>
          {auth.authHeader && <Header handleSignout={handleSignout}/>}
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/orders" component={OrdersList} />
            <PrivateRoute path="/orders/:id" component={Order} />
            <PrivateRoute exact path="/teachers" component={TeachersList} />
            <PrivateRoute path="/teachers/:id" component={Teacher} />
            <PrivateRoute exact path="/pupils" component={PupilsList} />
            <PrivateRoute path="/pupils/:id" component={Pupil} />
            <PrivateRoute exact path="/passes" component={PassesList} />
            <PrivateRoute exact path="/subscriptions" component={SubscriptionsList} />
            <Route path='*' exact={true}>
              <Redirect to="/"/>
            </Route>
          </Switch>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
