import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import styles from './Header.module.css';

import colors from '../../utils/colors';

const Header = ({ handleSignout }) => {
  const [showMenu, setShowMenu] = useState(false);

  return(
    <nav style={{ width: '100%', minHeight: 50, backgroundColor: colors.dark_blue, position: 'absolute', top: 0, display: 'flex', alignItems: isMobile ? 'flex-start' : 'center', zIndex: 2, flexDirection: isMobile ? 'column' : 'row' }}>
      {isMobile && (
        <div style={{ margin: 5, marginLeft: 15 }} onClick={() => setShowMenu(!showMenu)}>
          <img alt='logo' src={require('../../assets/logo.png')} style={{ width: 50 }} />
        </div>
      )}
      {!isMobile || showMenu ? (
        <ul style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center', margin: 0, paddingInlineStart: 10 }} onClick={() => setShowMenu(false)}>
          <li className={styles.linkContainer} style={{ marginLeft: 0 }}>
            <Link style={{ fontWeight: 'bold', textDecoration: 'none', color: '#fff', fontSize: '0.8em' }} to="/">
              {isMobile ? 'Accueil' : <img alt='logo' src={require('../../assets/logo.png')} style={{ width: 50 }} />}
            </Link>
          </li>
          <li className={styles.linkContainer}>
            <Link style={{ fontWeight: 'bold', textDecoration: 'none', color: '#fff', fontSize: '0.8em' }} to="/orders">Cours</Link>
          </li>
          <li className={styles.linkContainer}>
            <Link style={{ fontWeight: 'bold', textDecoration: 'none', color: '#fff', fontSize: '0.8em' }} to="/teachers">Professeurs</Link>
          </li>
          <li className={styles.linkContainer}>
            <Link style={{ fontWeight: 'bold', textDecoration: 'none', color: '#fff', fontSize: '0.8em' }} to="/pupils">Elèves</Link>
          </li>
          <li className={styles.linkContainer}>
            <Link style={{ fontWeight: 'bold', textDecoration: 'none', color: '#fff', fontSize: '0.8em' }} to="/passes">Pass</Link>
          </li>
          <li className={styles.linkContainer}>
            <Link style={{ fontWeight: 'bold', textDecoration: 'none', color: '#fff', fontSize: '0.8em' }} to="/subscriptions">Abos</Link>
          </li>
          <li className={`${styles.linkContainer} ${styles.lastLinkContainer}`}>
            <div style={{ fontWeight: 'bold', textDecoration: 'none', color: '#fff', fontSize: '0.8em', cursor: 'pointer' }} onClick={handleSignout}>Déconnexion</div>
          </li>
        </ul>
      ) : (
        null
        )}
    </nav>
  );
};

export default Header;
