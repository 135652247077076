import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import IosAdd from 'react-ionicons/lib/IosAdd';
import IosTrashOutline from 'react-ionicons/lib/IosTrashOutline';
import IosCheckmark from 'react-ionicons/lib/IosCheckmark';
import IosCreateOutline from 'react-ionicons/lib/IosCreateOutline';
import IosArrowBack from 'react-ionicons/lib/IosArrowBack';

import { get, find, post } from '../services/dbAccess';
import { patch } from '../services/api';

import colors from '../utils/colors';
import OrdersList from './OrdersList'
import Button from './Button'
import Loading from './Loading/Loading';

const customStyles = {
  content : {
    height: '70%',
    width: '50%',
    padding: 20,
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

class TopicsModal extends Component {
  constructor(props) {
    super(props)
    this.state = { newTopics: props.rawTopics };
  }

  componentDidMount() {
    this.fetchTopics();
    this.fetchLevels();
  }

  fetchTopics = async () => {
    try {
      const { docs: topics } = await find({ selector: { type: 'topic' } });
      this.setState({ topics })
    } catch (err) {
      console.error('Error getting Topics', err);
    }
  }

  fetchLevels = async () => {
    try {
      let { docs: levels } = await find({ selector: { type: 'level' } });
      const levelsOrder = ['CP', 'CE1', 'CE2', 'CM1', 'CM2', 'Sixième', 'Cinquième', 'Quatrième', 'Troisième', 'Seconde', 'Première', 'Terminale']
      levels = levels.sort((a, b) => levelsOrder.indexOf(b.name) - levelsOrder.indexOf(a.name));
      this.setState({ levels })
    } catch (err) {
      console.error('Error getting Levels', err);
    }
  }

  deleteTopic = (topic) => {
    const newTopics = this.state.newTopics.filter(t => t._id !== topic._id)
    this.setState({ newTopics })
  }

  validateTopic = () => {
    if (this.state.topic.levels.length < 1) {
      this.setState({ topic: null })
      return
    }
    console.log(this.state.topic)
    const newTopic = {
      _id: this.state.topic._id,
      levels: this.state.topic.levels,
    }
    this.setState((state) => {
      const index = state.newTopics.findIndex(t => t._id === this.state.topic._id)
      if (index > -1) {
        state.newTopics[index] = newTopic
      } else {
        state.newTopics.push(newTopic)
      }
      state.topic = null
      return state
    })
  }

  saveChanges = async () => {
    console.log('new topics soon updated', this.state.newTopics)
    const teacher = {
      ...this.props.teacher,
      topics: this.state.newTopics
    }
    console.log('old teacher', this.props.teacher)
    console.log('new teacher', teacher)
    const result = await post(teacher);
    alert('Changements sauvegardés', result)
    this.props.closeModal()
  }

  render() {
    if (!this.state.topics || !this.state.levels || !this.state.newTopics) {
      return <p>Loading...</p>
    }
    console.log('topic', this.state.topic)
    return (
      <div style={{ margin: 50, marginBottom: 20 }}>
        {this.state.topics && (!this.state.topic) ? <div>
          {this.state.topics.map(t => (
            <div key={t._id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p>{t.name}</p>
              {this.state.newTopics.filter(tt => tt._id === t._id).length > 0 ? (
                <div style={{ display: 'flex' }}>
                  <IosTrashOutline fontSize='30px' style={{ marginRight: 10 }} onClick={() => this.deleteTopic(t)} color={colors.error} />
                  <IosCreateOutline fontSize='30px' onClick={() => this.setState({ topic: this.state.newTopics.filter(nt => nt._id === t._id)[0] })} color={colors.blue}/>
                </div>
              ) : (
                <IosAdd fontSize='30px' onClick={() => this.setState({ topic: { ...t, levels: [] } })} color={colors.gray} />
              )}
            </div>
          ))}
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 50 }}>
            <Button style={{ marginRight: 10 }} type='danger' handleClick={this.props.closeModal} text='Annuler' />
            <Button type='validate' handleClick={this.saveChanges} text='Confirmer' />
          </div>
        </div> : <div>
          <IosArrowBack fontSize='30px' onClick={this.validateTopic} style={{ marginLeft: -40, marginBottom: 30 }} />
          {this.state.levels.map(l => (
            <div key={l._id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <p>{l.name}</p>
              {this.state.topic.levels.filter(ll => ll === l._id).length > 0 ? (
                <IosCheckmark fontSize='30px' onClick={() => this.setState((state) => {
                    state.topic.levels = state.topic.levels.filter(ll => ll !== l._id)
                    return state
                  })} color={colors.green}
                />
              ) : (
                <IosAdd fontSize='30px' onClick={() => this.setState((state) => {
                    state.topic.levels = [ ...state.topic.levels, l._id ]
                    return state
                  })} color={colors.gray}
                />
              )}
            </div>
          ))}
        </div>}
      </div>
    )
  }
}

class RatesModal extends Component {
  render() {
    return (
      <div>
        {this.props.rates.map(r => (
          <div style={{display: 'flex', flexDirection: 'row'}} key={r.orderId}>
            <div>
              <p>General: {r.general}</p>
              <Link style={{ textDecoration: 'none', color: '#227' }} to={`/orders/${r.orderId}`}>Voir le cours ({r.orderId})</Link>
            </div>
            <div>
              <p>Presentation: {r.presentation}</p>
              <p>Etat d'esprit: {r.mind}</p>
              <p>Patience: {r.patience}</p>
              <p>Pédagogie: {r.pedago}</p>
              <p>Déroulement du cours: {r.course}</p>
              {r.text && <p>Précisions: {r.text}</p>}
            </div>
          </div>
        ))}
      </div>
    )
  }
}

class Teacher extends Component {

  constructor(props) {
    super(props);
    this.state = { teacher: null, teacherTopics: 'loading', orders: [], topicsModal: false, newZone: '' }
  }

  componentDidMount() {
    this.fetchTeacher();
  }

  fetchTeacher = async () => {
    try {
      const teacher = await get(this.props.match.params.id, { attachments: true })
      this.setState({ teacher, rawTopics: teacher.topics ? JSON.parse(JSON.stringify(teacher.topics)) : [] })
      const { docs: orders } = await find({ selector: { $or: [{ teacher: teacher._id}, { preferredTeacher: teacher._id }] }, limit: 1000, sort: [{ date: 'desc' }]})
      if (teacher.rates) {
        if (teacher.rates.length > 1) {
          teacher.rate = Math.round((teacher.rates.reduce((a, b) => (a + b.general), 0) / teacher.rates.length) * 10) / 10
        } else {
          teacher.rate = teacher.rates[0].general
        }
      } else {
        teacher.rate = 'Aucune note pour le moment'
      }
      console.log(teacher)
      this.setState({ teacher, orders })
      if (teacher.topics) {
        for (let i = 0; i < teacher.topics.length; i++) {
          let { name } = await get(teacher.topics[i]._id)
          teacher.topics[i].name = name
          let level;
          for (let j = 0; j < teacher.topics[i].levels.length; j++) {
            console.log(teacher.topics[i].levels[j])
            level = await get(teacher.topics[i].levels[j])
            teacher.topics[i].levels[j] = level.name
          }
        }
        this.setState({ teacherTopics: 'loaded', teacher })
      } else {
        this.setState({ teacherTopics: null })
      }
    } catch (err) {
      console.error('Error fetchTeacher', err)
    }
  }

  closeModal = () => {
    this.setState({ topicsModal: false, teacherTopics: 'loading' });
    this.fetchTeacher();
  }

  toggleValidated = async () => {
    const teacher = await get(this.props.match.params.id)
    teacher.pictureValidated = !teacher.pictureValidated
    await post(teacher)
    await this.fetchTeacher()
  }

  addZone = async () => {
    if (this.state.newZone.length !== 2) alert('Erreur, Le nouveau département doit comporter 2 caractères');
    else if (this.state.teacher.zones?.includes(this.state.newZone)) alert('Erreur, Le professeur a déjà ce département');
    else {
      await patch(`/admin/teachers/${this.state.teacher._id}`, {
        ...this.state.teacher,
        zones: [ ...(this.state.teacher.zones || []), this.state.newZone ],
      });
      alert('Changements sauvegardés');
      this.setState({ newZone: '' });
      await this.fetchTeacher();
    }
  }

  removeZone = async (zone) => {
    await patch(`/admin/teachers/${this.state.teacher._id}`, {
      ...this.state.teacher,
      zones: this.state.teacher.zones.filter(z => z !== zone),
    });
    alert('Changements sauvegardés');
    await this.fetchTeacher();
  }

  render() {
    if (!this.state.teacher) return <Loading/>;
    return <div>
      <div style={{ margin: 20 }}>
        {this.state.teacher._attachments && this.state.teacher._attachments["picture.png"]?.data && <img style={{ height: 150, resizeMode: 'contain' }} src={`data:image/jpeg;base64,${this.state.teacher._attachments["picture.png"].data}`} />}
        <div>
          <label style={{marginRight: 5}}>Photo validée</label>
          <input type='checkbox' checked={this.state.teacher?.pictureValidated} onChange={this.toggleValidated} />
        </div>
        <h2>{this.state.teacher.firstname} {this.state.teacher.lastname}</h2>
        <p><a style={{ color: 'black', cursor: 'pointer', textDecoration: 'none' }} href={`https://db.expliquemoipythagore.fr/_utils/#database/pythagore-shared/${this.state.teacher._id}`}>{this.state.teacher._id}</a></p>
        <p>{this.state.teacher.number}</p>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <p>Note : {this.state.teacher.rate}</p>
          {this.state.teacher.rates && this.state.teacher.rates.length > 0 && <IosAdd fontSize='25px' onClick={() => this.setState({ ratesModal: true })} color={colors.dark_blue} />}
        </div>
        <p>Inscrit le: {(new Date(this.state.teacher.signUp ? this.state.teacher.signUp.timestamp : this.state.teacher.createdAt)).toLocaleString()}</p>
        {this.state.teacher.birthdate && <p>{(new Date(this.state.teacher.birthdate)).toLocaleString()}</p>}
        {this.state.teacher.secu_number && <p>{this.state.teacher.secu_number}</p>}
        <p>Départements : <ul>
          {this.state.teacher.zones?.map(z => (
            <li style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 20 }} key={z}>
              <p>{z}</p>
              <div style={{ marginLeft: 5,backgroundColor: 'red', height: 15, width: 15, borderRadius: 10, cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => this.removeZone(z)}>
                <p style={{ color: 'white', fontSize: 10 }}>X</p>
              </div>
            </li>
          ))}
          <input type="text" value={this.state.newZone} onChange={(event) => this.setState({ newZone: event.target.value })}/>
          <button onClick={this.addZone}>Ajouter</button>
        </ul></p>
        {this.state.teacher.devices && <div>
          <p>Devices (notifs) :</p>
          {this.state.teacher.devices.map(d => (
            <p>- {d}</p>
          ))}
        </div>}
        {this.state.teacher.address && <p>{this.state.teacher.address.number} {this.state.teacher.address.street} {this.state.teacher.address.zipcode} {this.state.teacher.address.city}</p>}
        {this.state.orders && <OrdersList orders={this.state.orders} />}
        {this.state.teacherTopics === 'loaded' ? (
          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <h2 style={{ fontSize: 15, marginRight: 20 }}>Matières ({this.state.teacher.topics.length})</h2>
              <Button handleClick={() => this.setState({ topicsModal: true })} text='Modifier'/>
            </div>
            {this.state.teacher.topics.map(t => (
              <div key={t._id} style={{ marginLeft: 20 }}>
                <p style={{ color: '#227' }} >{t.name}</p>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 30 }}>
                  {t.levels.map((l, i) => (
                    <p key={i} style={{ margin: 5 }}>{l}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (this.state.teacherTopics === 'loading' ? (
            <p>Chargements des matières et des niveaux...</p>
          ) : (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{ fontSize: 15, marginRight: 20, color: colors.error }}>Matières et niveaux non renseignés</p>
              <Button handleClick={() => this.setState({ topicsModal: true })} text='Ajouter'/>
            </div>
          )
        )}
      </div>
      <Modal
        isOpen={this.state.topicsModal}
        onAfterOpen={() => console.log('after open')}
        onRequestClose={this.closeModal}
        style={customStyles}
      >
        <h2 style={{ marginLeft: 20 }}>Modifier les matières et les niveaux</h2>
        <TopicsModal teacher={this.state.teacher} rawTopics={this.state.rawTopics} closeModal={this.closeModal} />
      </Modal>
      <Modal
        isOpen={this.state.ratesModal}
        onAfterOpen={() => console.log('after open')}
        style={customStyles}
        onRequestClose={() => this.setState({ ratesModal: false })}
      >
        <h2 style={{ marginLeft: 20 }}>Détails des notes</h2>
        {this.state.teacher && <RatesModal rates={this.state.teacher.rates}/>}
      </Modal>
    </div>
  }
}

export default withRouter(Teacher);
