import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTable, useBlockLayout, useResizeColumns, usePagination } from 'react-table';

import { getColor } from '../../utils';
import styles from './PowerfulTable.module.css';
import { download } from '../../services/api';

const dateRegex = new RegExp(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/);
const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

const formatData = (data) => {
  if (typeof(data) === 'string' && dateRegex.test(data)) {
    return moment(data).format('DD-MM-YYYY à HH[h]mm');
  }
  switch(typeof(data)) {
    case 'string':
      return data;
    case 'number':
      return data.toString();
    case 'boolean':
      return data ? '✅' : '❌';
    case 'object':
      return data.name || data.status
    default:
      return data?.toString() || '-';
  }
}

const PowerfulTable = (props) => {
  const data = React.useMemo(
    () => props.data.map(d => {
      const obj = {};
      Object.keys(props.types).forEach(k => {
        obj[k] = formatData(d[k]);
      });
      return obj;
    }),
    [props.data, props.types]
  )

  const columns = React.useMemo(
    () => ([
      ...Object.keys(props.types).map(key => ({
        Header: props.types[key].name,
        accessor: key,
        width:
            ['email', 'pupil', 'teacher', 'user'].includes(key) ? 180 :
            ['createdOn', 'createdAt', 'signup', 'createdBy', 'end_sub', 'date'].includes(key) ? 150 :
            ['duration', 'status', 'level', 'rate', 'zipcode', 'subscription'].includes(key) ? 80 :
            130,
        minWidth: 30,
        Cell: ((cell) => {
          let { value } = cell;
          let showedValue = value;
          let style = {
            padding: '2px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          };
          let textStyle = {
            fontSize: '0.9em',
            textOverflow: 'ellipsis',
            margin: 0,
            marginBlockStart: 0,
            marginBlockEnd: 0,
            lineHeight: '30px',
            maxWidth: '95%',
            overflow: 'hidden',
          }
          let onClick = () => null;
          if (cell.column.id === '_id') {
            onClick = () => navigator.clipboard.writeText(cell.value);
            style.cursor = 'pointer';
            textStyle.fontWeight = 'bold';
            if (cell.value.length === 32) value = 'Copy ID';
          }
          if (getColor(cell.value) !== 'black') {
            style.color = getColor(cell.value);
          }
          // console.log('cell', cell);
          if ((cell.column.id === 'amount' || cell.column.id === 'rate') && parseInt(cell.value, 10)) {
            value = (parseInt(cell.value, 10) / 100).toFixed(2)
          } else if (['teacher', 'pupil', 'createdBy', 'user'].includes(cell.column.id)) {
            style.cursor = 'pointer';
            textStyle.fontWeight = 'bold';
            if (cell.column.id === 'teacher') {
              onClick = () => window.location.href = '/teachers/' + value;
            } else {
              onClick = () => window.location.href = '/pupils/' + value;
            }
          } else if (cell.column.id === 'bill') {
            style.cursor = 'pointer';
            textStyle.textDecoration = 'underline';
            textStyle.color = 'blue';
            textStyle.fontSize = 14;
            console.log('value', value);
            onClick = () => {
              download(value);
            }
            showedValue = 'Télécharger';
          }

          return <div style={style} onClick={onClick}><p style={textStyle}>{showedValue}</p></div>;
        }),
      })),
      {
        Header: 'Détails',
        accessor: 'link',
        Cell: ({ row }) => (<Link to={{ pathname: `/${props.redirect}/${row?.values[props.redirectKey]}` }}>Voir</Link>),
        width: 70,
        minWidth: 30,
      },
      {
        Header: 'Database',
        accessor: 'db-link',
        Cell: ({ row }) => (<a target={"_blank"} href={`https://db.expliquemoipythagore.fr/_utils/#database/pythagore-shared/${row?.values[props.redirectKey]}`}>DB</a>),
        width: 80,
        minWidth: 30,
      },
    ]),
    [props.redirect, props.redirectKey, props.types],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize
  } = useTable({ columns, data, initialState: { pageSize: 200 }, style: { height: '400px' } }, useBlockLayout, useResizeColumns, usePagination)

  return (
    <div className={styles.container}>
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  className={`${styles.cell} ${styles.capitalize}`}
                >
                  {column.render('Header')}
                  <div
                  {...column.getResizerProps()}
                  className={`${styles.resizer} ${column.isResizing && styles.isResizing}`}
                />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps([
                        { className: styles.cell }
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <div className={styles.pageSelect}>
          <select style={{ marginRight: 10 }} value={pageSize} onChange={(e) => setPageSize(e.target.value)}>
            <option value={10}>10</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={500}>500</option>
          </select>
          <p>lignes / page</p>
        </div>

        <div className={styles.pageSelect}>
          <p>Page</p>
          <select style={{ marginLeft: 10, marginRight: 10 }} value={pageIndex} onChange={(e) => gotoPage(e.target.value)}>
            {[ ...Array(pageOptions.length).keys() ].map(i => (
              <option key={i} value={i}>{i + 1}</option>
            ))}
          </select>
          <p>/ {pageOptions.length}</p>
        </div>
      </div>
    </div>
  )
}

export default PowerfulTable;
