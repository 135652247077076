import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { get } from '../services/api';
import Loading from './Loading/Loading';
import PowerfulTable from './PowerfulTable/PowerfulTable';

export default class PassesList extends Component {

  constructor(props) {
    super(props);
    this.state = { passes: undefined, filter: '', filteredPasses: undefined, showCreated: false };
  }

  componentDidMount() {
    this.fetchPasses()
  }

  componentWillReceiveProps(newProps) {
    this.setState({ filter: '' })
    if (newProps.passes) {
      this.setState({passes: newProps.passes, filteredPasses: newProps.passes, filter: ''})
    } else {
      this.fetchPasses()
    }
  }

  fetchPasses = async () => {
    const passes = await get(`/admin/passes?limit=1000`);
    this.setState({ passes, filteredPasses: passes, filter: '' });
  }

  filter = (event) => {
    const s = event.target.value.toLowerCase()
    let filteredPasses = this.state.passes.filter((p) => {
      return (p._id.toLowerCase().includes(s) || p.pupil.toLowerCase().includes(s) || p.status.toLowerCase().includes(s) || p.months.toString().toLowerCase().includes(s) || (p.amount / 100).toString().toLowerCase().includes(s) || moment(new Date(p.createdOn)).format('dd DD/MM/YYYY à HH[h]mm').toLowerCase().includes(s))
    })
    this.setState({ filter: event.target.value, filteredPasses })
  }

  render() {
    if (!this.state.filteredPasses) return <Loading />
    return  <div style={{ marginLeft: 20 }}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>Pass ({this.state.filteredPasses.filter(p => (p.status !== 'created' || this.state.showCreated)).length})</h2>
        <div>
          <label style={{marginRight: 5}}>Afficher les achats en cours</label>
          <input type='checkbox' value={this.state.showCreated} onChange={(e) => this.setState({ showCreated: e.target.checked })} />
        </div>
        <div style={{marginRight: 50}}>
          <input placeholder='Filtrer...' type='text' onChange={this.filter} value={this.state.filter}
            style={{ paddingLeft: 10, width: 180, height: 30, borderRadius: 30, boxShadow: 'none', borderStyle: 'hidden', backgroundColor: '#eee', outline: 'none' }}
          />
        </div>
      </div>

      <PowerfulTable
        data={this.state.filteredPasses}
        types={{
          _id: { name: 'id' },
          pupil: { name: 'élève' },
          amount: { name: 'montant (€)' },
          months: { name: 'mois' },
          status: { name: 'statut' },
        }}
        redirect={'passes'}
        redirectKey={'_id'}
      />

    </div>
  }
}
