import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import IosCheckmark from 'react-ionicons/lib/IosCheckmark';

import { post, find } from '../services/dbAccess';
import { get } from '../services/api';
import { getColor } from '../utils';
import colors from '../utils/colors';
import Loading from './Loading/Loading';

const customStyles = {
  content : {
    height: '50%',
    width: '30%',
    padding: 20,
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

class Order extends Component {

  constructor(props) {
    super(props);
    this.state = {
      order: null,
      statusModal: false,
      teachersModal: false,
      teachers: null
    }
  }

  componentDidMount() {
    this.fetchOrder();
  }

  fetchOrder = async () => {
    const order = await get(`/admin/orders/${this.props.match.params.id}`);
    console.log(order)
    this.setState({ order })
  }

  closeStatusModal = async (newStatus) => {
    this.setState({ statusModal: false });
    const order = this.state.order;
    if (newStatus === 'ordered') {
      delete order.teacher
    }
    order.status = newStatus;
    await post(order);
    this.fetchOrder();
  }

  openTeachersModal = async () => {
    const { docs: teachers } = await find({ selector: { type: 'user', user_type: 'teacher' }, limit: 1000000 })
    this.setState({ teachers, teachersModal: true })
  }

  closeTeachersModal = async (newTeacher) => {
    this.setState({ teachersModal: false, teachers: null });
    const order = this.state.order;
    if (order.status === 'ordered') {
      order.status = 'confirmed'
    }
    order.teacher = newTeacher;
    await post(order);
    this.fetchOrder();
  }

  render() {
    if (!this.state.order) return <Loading/>;
    return <div>
      <div style={{ margin: 20 }}>
        <h2><a style={{ color: 'black', cursor: 'pointer', textDecoration: 'none' }} href={`https://db.expliquemoipythagore.fr/_utils/#database/pythagore-shared/${this.state.order._id}`}>{this.state.order._id}</a></h2>
        <p onClick={() => this.setState({ statusModal: true })} style={{ color: getColor(this.state.order.status) }}>{this.state.order.status.slice(0, 1).toUpperCase()}{this.state.order.status.slice(1)}</p>
        <p>{(new Date(this.state.order.date)).toLocaleString()}</p>
        <p>Commandé le {(new Date(this.state.order.createdOn)).toLocaleString()}</p>
        <p>{this.state.order.topic.name}</p>
        <p>{this.state.order.level.name}</p>
        <p>{this.state.order.duration} heures</p>
        {!this.state.order.remote && <p>{this.state.order.address.number} {this.state.order.address.street}</p>}
        {!this.state.order.remote && <p>{this.state.order.address.zipcode} {this.state.order.address.city}</p>}
        {this.state.order.remote && <p>{'à'.toUpperCase()} distance</p>}
        {this.state.order.precisions ? <p>{this.state.order.precisions}</p> : <p>Pas de précision</p>}
        {this.state.order.cancelledBy && <p>Annulé par {this.state.order.cancelledBy}</p>}
        <p>
          Elève : <Link style={{ textDecoration: 'none', color: '#227' }} to={`/pupils/${this.state.order.createdBy}`}>{this.state.order.createdBy}</Link>
        </p>
        {this.state.order.preferredTeacher && <p>
          Professeur Demandé: <Link style={{ textDecoration: 'none', color: '#227' }} to={`/teachers/${this.state.order.preferredTeacher.email ? this.state.order.preferredTeacher.email : this.state.order.preferredTeacher}`}>{this.state.order.preferredTeacher.email ? this.state.order.preferredTeacher.email : this.state.order.preferredTeacher}</Link>
        </p>}
        {(this.state.order.teacher) ? <div style={{ display: 'flex', flexDirection: 'row' }}><p>
            Professeur : <Link style={{ textDecoration: 'none', color: '#227' }} to={`/teachers/${this.state.order.teacher}`}>{this.state.order.teacher}</Link>
          </p><p style={{ color: colors.dark_blue, marginLeft: 5 }} onClick={this.openTeachersModal}>
           (Modifier)
          </p></div> : <p style={{ color: colors.dark_blue }} onClick={this.openTeachersModal}>
            Sélectionner un professeur
          </p>
        }
        {this.state.order.refusedBy?.length > 0 && <div>
          <p>Refusé par :</p>
          {this.state.order.refusedBy.map(r => (
            <p>- {r}</p>
          ))}
        </div>}
        {this.state.order.acceptedBy?.length > 0 && <div>
          <p>Accepté par :</p>
          {this.state.order.acceptedBy.map(r => (
            <p>- {r}</p>
          ))}
        </div>}
        {this.state.order.blacklisted?.length > 0 && <div>
          <p>Professeurs blacklistés :</p>
          {this.state.order.blacklisted.map(r => (
            <p>- {r}</p>
          ))}
        </div>}
        {this.state.order.status === 'done' && <p style={{ color: this.state.order.teacherRated ? 'green' : 'red' }}>
          {this.state.order.teacherRated ? 'Professeur noté' : 'Professeur non noté'}
        </p>}
        {this.state.order.status === 'done' && <p style={{ color: this.state.order.pupilRated ? 'green' : 'red' }}>
          {this.state.order.pupilRated ? 'Elève noté' : 'Elève non noté'}
        </p>}
      </div>
      <Modal
        isOpen={this.state.statusModal}
        onRequestClose={() => this.setState({ statusModal: false })}
        style={customStyles}
        ariaHideApp={false}
      >
        <p style={{color: colors.error}} onClick={() => this.setState({ statusModal: false}) }>Annuler</p>
        <h2 style={{ marginLeft: 20 }}>Modifier le statut du cours</h2>
        <div>
          {['ordered', 'assigned', 'confirmed', 'done', 'cancelled'].map(s => (
            <div key={s} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} onClick={() => this.closeStatusModal(s)}>
              <p style={{ color: getColor(s) }}>{s.slice ? `${s.slice(0, 1).toUpperCase()}${s.slice(1)}` : 'statut'}</p>
              {this.state.order && this.state.order.status === s && <IosCheckmark fontSize='30px' color={getColor(this.state.order.status)} />}
            </div>
          ))}
        </div>
      </Modal>

      <Modal
        isOpen={this.state.teachersModal}
        onRequestClose={() => { this.setState({ teachersModal: false })}}
        style={customStyles}
        ariaHideApp={false}
      >
        <p style={{color: colors.error}} onClick={() => this.setState({ teachersModal: false}) }>Annuler</p>
        <h2 style={{ marginLeft: 20 }}>Assigner un professeur</h2>
        <div>
          {this.state.teachers && this.state.teachers.map(t => (
            <div key={t._id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} onClick={() => this.closeTeachersModal(t._id)}>
              <p>{t._id}</p>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  }
}

export default withRouter(Order);
