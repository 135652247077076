import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import AuthContext from '../contexts/AuthContext';

import { post } from '../services/api';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = { email: '', password: '' }
  }

  signin = async (event) => {
    try {
      event.preventDefault()
      const { token } = await post('/admin/auth/signin', {
        email: this.state.email,
        password: this.state.password
      });
      if (!token) throw new Error('No token returned');
      this.context.saveHeader(`JWT ${token}`);
      this.props.history.push('/');
    } catch (err) {
      console.error('Error', err);
      alert('Mauvais identifiants', 'Veuillez réessayer')
    }
  }

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '100%'}}>
        <img alt='logo' src={require('./../assets/logo.png')} style={{ width: 250 }} />
        <h1 style={{marginBottm: 50, color: '#26c', fontSize: 40, fontWeight: 'bold'}}>Console d'administration</h1>
        <form onSubmit={this.signin} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%', backgroundColor: '#26c', padding: 50, borderRadius: 20}}>
          <label style={{color: 'white', fontWeight: 'bold'}}>Username</label>
          <input type='text' name='email' autoComplete='username' onChange={(event) => this.setState({ email: event.target.value })} style={{ paddingLeft: 10, width: 200, height: 30, borderRadius: 30, marginTop: 10, marginBottom: 10, outline: 'none', borderStyle: 'hidden', backgroundColor: '#eee' }}/>
          <label style={{color: 'white', fontWeight: 'bold'}}>Password</label>
          <input type='password' name='password' autoComplete='current-password' onChange={(event) => this.setState({ password: event.target.value })} style={{ paddingLeft: 10, width: 200, height: 30, borderRadius: 30, marginTop: 10, marginBottom: 10, outline: 'none', borderStyle: 'hidden', backgroundColor: '#eee' }}/>
          <input type='submit' value='Se connecter' />
        </form>
      </div>
    )
  }
}

Login.contextType = AuthContext;
export default withRouter(Login);
