import React, { Component } from 'react';

import colors from '../utils/colors'

export default class Button extends Component {

  getColor = () => {
    switch(this.props.type) {
      case 'validate':
        return colors.green
      case 'danger':
        return colors.error
      default:
        return colors.blue
    }
  }

  render() {
    return <button onClick={this.props.handleClick} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 180, height: 50, backgroundColor: this.getColor(), borderRadius: 10, borderWidth: 0, outline: 'none', ...this.props.style }}>
      <p style={{ color: 'white', fontWeight: 'bold', fontSize: 15 }}>{this.props.text || 'Ok'}</p>
    </button>
  }
}
