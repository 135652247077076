import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import OrdersList from './OrdersList';
import SubscriptionsList from './SubscriptionsList';
import { get, _delete } from '../services/api';
import { post, find } from '../services/dbAccess';
import Loading from './Loading/Loading';

class Pupil extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pupil: null,
      changeDate: false
    }
  }

  componentDidMount() {
    this.fetchPupil();
  }

  fetchPupil = async () => {
    const pupil = await get(`/admin/pupils/${this.props.match.params.id}`);
    this.setState({ pupil })
  }

  changeEndSub = async (date) => {
    const pupil = this.state.pupil
    pupil.end_sub = date.toISOString()
    await post(pupil)
    await this.fetchPupil()
  }

  deleteSubscription = async () => {
    await _delete(`/admin/pupils/${this.state.pupil._id}/subscription`);
    await this.fetchPupil();
  }

  render() {
    let engaged = false;
    if (this.state.pupil?.subscription) {
      const subscription = this.state.pupil.subscription;
      if (subscription.monthly_fee === 2500) {
        engaged = new Date(subscription.engagement_end);
      } else if (subscription.monthly_fee === 1500) {
        const registerDate = new Date(this.state.pupil.signUp ? this.state.pupil.signUp.timestamp : this.state.pupil.createdAt)
        if (registerDate > new Date('2022-03-01')) {
          engaged = new Date(subscription.engagement_end);
        }
      }
    }
    if (!this.state.pupil) return <Loading/>;
    return <div>
      <div style={{ margin: 20 }}>
        <h2>{this.state.pupil.firstname} {this.state.pupil.lastname}</h2>
        <p><a style={{ color: 'black', cursor: 'pointer', textDecoration: 'none' }} href={`https://db.expliquemoipythagore.fr/_utils/#database/pythagore-shared/${this.state.pupil._id}`}>{this.state.pupil._id}</a></p>
        <p>Numéro de téléphone: {this.state.pupil.number}</p>
        {this.state.pupil.birthdate && <p>Date de naissance: {(new Date(this.state.pupil.birthdate)).toLocaleString()}</p>}
        <p>Inscrit le: {(new Date(this.state.pupil.signUp ? this.state.pupil.signUp.timestamp : this.state.pupil.createdAt)).toLocaleString()}</p>
        {this.state.pupil.devices && <div>
          <p>Devices (notifs) :</p>
          {this.state.pupil.devices.map(d => (
            <p>- {d}</p>
          ))}
        </div>}
        <p>Abonné: {this.state.pupil.subscription?.status === 'active' ?
          `✅ ${engaged ? `Engagé jusqu\\'au ${moment(engaged).format('DD/MM/yy')}` : 'Sans engagement'}` :
          `❌ ${this.state.pupil.subscription?.cancelled ?
          `Résilié le ${moment(this.state.pupil.subscription?.cancelledAt).format('DD/MM/yy')}` : ''}`
        }</p>
        {!!this.state.pupil.subscription &&
          <span onClick={this.deleteSubscription} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Supprimer l'abonnement</span>
        }
        <p>Fin du pass: {!this.state.pupil.end_sub && '❌. Ajouter un pass :'}<DatePicker dateFormat='dd-MM-yy' selected={this.state.pupil.end_sub ? (new Date(this.state.pupil.end_sub)) : (new Date())} onChange={this.changeEndSub} /></p>
        {this.state.pupil.address && <p>Addresse: {this.state.pupil.address.number} {this.state.pupil.address.street}, {this.state.pupil.address.zipcode} {this.state.pupil.address.city} ({this.state.pupil.address.complement}) </p>}
        {this.state.pupil.blacklisted && <div>
          <p>Professeurs blacklistés :</p>
          {this.state.pupil.blacklisted.map(r => (
            <p>- {r}</p>
          ))}
        </div>}
        {this.state.pupil.orders && <OrdersList orders={this.state.pupil.orders} />}
        {this.state.pupil.subscriptions && <SubscriptionsList subscriptions={this.state.pupil.subscriptions} />}
      </div>
    </div>
  }
}

export default withRouter(Pupil);
