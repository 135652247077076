import React from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import styles from './Loading.module.css';
import colors from '../../utils/colors';

const Loading = () => {
  return (
    <div className={styles.loadingContainer}>
      <Loader
        type="Ball-Triangle"
        color={colors.dark_blue}
        height={100}
        width={100}
      />
      <p className={styles.loadingText}>Chargement ...</p>
    </div>
  );
}

export default Loading;
