import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ResponsiveLine } from '@nivo/line'

import { find } from '../services/dbAccess';
import colors from '../utils/colors';
import Loading from './Loading/Loading';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { order: null }
  }

  componentDidMount() {
    this.fetchUsers();
    this.fetchOrders();
    this.fetchPasses();
    this.fetchSubscriptions();
  }

  sameDay = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  prettyNumber = (n) => {
    n = n.toString()
    if (n.length === 1)
      return '0' + n
    return n
  }

  prettyDate = (date) => {
    return this.prettyNumber(date.getDate()) + '/' + this.prettyNumber((date.getMonth() + 1)) + '/' + date.getFullYear()
  }

  fetchUsers = async () => {
    let { docs: users } = await find({ selector: { type: 'user' }, limit: 1000 });
    users = users.sort((a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()))
    let data = []
    let dataT = []
    let dataP = []
    let begin = new Date(users[0].createdAt)
    const end = new Date()
    for (; begin < end; begin.setDate(begin.getDate() + 1)) {
      data.push({
        x: new Date(begin),
        y: 0
      })
      dataT.push({
        x: new Date(begin),
        y: 0
      })
      dataP.push({
        x: new Date(begin),
        y: 0
      })
    }
    for (const u of users) {
      const userSignup = new Date(u.signUp ? u.signUp.timestamp : u.createdAt)
      const i = data.findIndex(d => this.sameDay(d.x, userSignup))
      if (i > -1) {
        data[i].y += 1
        if (u.user_type === 'teacher') {
          dataT[i].y += 1
        } else if (u.user_type === 'pupil') {
          dataP[i].y += 1
        }
      }
    }
    data = data.sort((a, b) => a.x - b.x)
    dataT = dataT.sort((a, b) => a.x - b.x)
    dataP = dataP.sort((a, b) => a.x - b.x)
    let previous = 0;
    data = data.map(d => {
      let tmp = d.y
      const newData = { x: d.x, y: d.y + previous }
      previous = tmp + previous
      return newData
    })
    previous = 0
    dataT = dataT.map(d => {
      let tmp = d.y
      const newData = { x: d.x, y: d.y + previous }
      previous = tmp + previous
      return newData
    })
    previous = 0
    dataP = dataP.map(d => {
      let tmp = d.y
      const newData = { x: d.x, y: d.y + previous }
      previous = tmp + previous
      return newData
    })
    const usersRaise = (data[data.length - 1].y - data[data.length - 7].y) / data[data.length - 7].y
    const usersAverage = (data[data.length - 1].y - data[data.length - 7].y) / 7
    this.setState({
      users: [{
        id: 'Professeurs',
        data: dataT
      }, {
        id: 'Elèves',
        data: dataP
      }, {
        id: 'Total',
        data: data
      }],
      usersRaise,
      usersAverage
    });
  }

  fetchOrders = async () => {
    let { docs: orders } = await find({ selector: { type: 'order', status: { $ne: 'cancelled' }, createdBy: { $nin: ['expliquemoipythagore@gmail.com', 'jeanbaumann94@gmail.com'] } }, limit: 10000 });
    console.log('orders', orders)
    orders = orders.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
    let data = []
    let volume = []
    let begin = new Date(orders[0].createdOn)
    let oneWeekAgo = new Date()
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
    console.log('begin1', begin)
    begin = begin < oneWeekAgo ? begin : oneWeekAgo
    console.log('begin', begin)
    const end = new Date()
    let i = 0;
    for (; begin < end; begin.setDate(begin.getDate() + 1)) {
      i += 1
      data.push({
        x: new Date(begin),
        y: 0
      })
      volume.push({
        x: new Date(begin),
        y: 0
      })
    }
    console.log('i', i)
    for (const o of orders) {
      const orderDate = new Date(o.createdOn)
      const i = data.findIndex(d => this.sameDay(d.x, orderDate))
      if (i > -1) {
        data[i].y += 1
        volume[i].y += o.remote ? o.level.remotePrice : o.level.price;
      }
    }
    let previous = 0;
    data = data.map(d => {
      let tmp = d.y
      const newData = { x: d.x, y: d.y + previous }
      previous = tmp + previous
      return newData
    })
    previous = 0;
    volume = volume.map(d => {
      let tmp = d.y
      const newData = { x: d.x, y: d.y + previous }
      previous = tmp + previous
      return newData
    })
    const ordersRaise = (data[data.length - 1].y - (data[data.length - 7].y === 0 ? 1 : data[data.length - 7].y)) / (data[data.length - 7].y === 0 ? 1 : data[data.length - 7].y)
    const ordersAverage = (data[data.length - 1].y - data[data.length - 7].y) / 7
    this.setState({
      ordersRaise,
      ordersAverage,
      orders: [{
        id: 'orders',
        data: data
      }, {
        id: 'volume',
        data: volume
      }]
    });
  }

  fetchPasses = async () => {
    let { docs: passes } = await find({ selector: { type: 'transaction', status: 'confirmed' }, limit: 10000 });
    passes = passes.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
    console.log('passes', passes)
    let data = []
    let begin = new Date(passes[0].createdOn)
    let oneWeekAgo = new Date()
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
    begin = begin < oneWeekAgo ? begin : oneWeekAgo
    const end = new Date()
    for (; begin < end; begin.setDate(begin.getDate() + 1)) {
      data.push({
        x: new Date(begin),
        y: 0
      })
    }
    for (const p of passes) {
      const passDate = new Date(p.createdOn)
      const i = data.findIndex(d => this.sameDay(d.x, passDate))
      if (i > -1) {
        data[i].y += (p.amount / 100)
      }
    }
    data = data.sort((a, b) => a.x - b.x)
    let previous = 0;
    data = data.map(d => {
      let tmp = d.y
      const newData = { x: d.x, y: d.y + previous }
      previous = tmp + previous
      return newData
    })
    const passesRaise = (data[data.length - 1].y - (data[data.length - 7].y === 0 ? 1 : data[data.length - 7].y)) / (data[data.length - 7].y === 0 ? 1 : data[data.length - 7].y)
    const passesAverage = (data[data.length - 1].y - data[0].y) / data.length
    const monthCa = (data[data.length - 1].y - data[data.length - 31].y)
    const totalCa = data[data.length - 1].y;
    this.setState({
      passes: [{
        id: 'passes',
        color: 'hsl(204, 100%, 50%)',
        data: data
      }],
      passesRaise,
      passesAverage,
      totalCa,
      monthCa,
    });
  }

  fetchSubscriptions = async () => {
    let { docs: subscriptions } = await find({ selector: { type: 'payment', status: 'confirmed' }, limit: 10000 });
    subscriptions = subscriptions.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
    console.log('subscriptions', subscriptions)
    let data = []
    let begin = new Date(subscriptions[0].createdOn)
    let oneWeekAgo = new Date()
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
    begin = begin < oneWeekAgo ? begin : oneWeekAgo
    const end = new Date()
    for (; begin < end; begin.setDate(begin.getDate() + 1)) {
      data.push({
        x: new Date(begin),
        y: 0
      })
    }
    for (const p of subscriptions) {
      const passDate = new Date(p.createdOn)
      const i = data.findIndex(d => this.sameDay(d.x, passDate))
      if (i > -1) {
        data[i].y += (p.amount / 100)
      }
    }
    data = data.sort((a, b) => a.x - b.x)
    let previous = 0;
    data = data.map(d => {
      let tmp = d.y
      const newData = { x: d.x, y: d.y + previous }
      previous = tmp + previous
      return newData
    })
    const subscriptionsRaise = (data[data.length - 1].y - (data[data.length - 7].y === 0 ? 1 : data[data.length - 7].y)) / (data[data.length - 7].y === 0 ? 1 : data[data.length - 7].y)
    const subscriptionsAverage = (data[data.length - 1].y - data[0].y) / data.length
    const monthCaSub = (data[data.length - 1].y - data[data.length - 31].y)
    const totalCaSub = data[data.length - 1].y;
    this.setState({
      subscriptions: [{
        id: 'subscriptions',
        color: 'hsl(204, 100%, 50%)',
        data: data
      }],
      subscriptionsRaise,
      subscriptionsAverage,
      totalCaSub,
      monthCaSub,
    });
  }

  render() {
    if (!this.state.users || !this.state.orders || !this.state.passes) return <Loading />

    return <div>
      {this.state.users ? (
        <div style={{ margin: 20, height: 400, width: '80%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2>Utilisateurs</h2>
            <p style={{ width: 300, color: '#11c', fontWeight: 'bold', fontSize: 20 }}>Moyenne sur 7 jours: + {Math.round(10 * this.state.usersAverage) / 10} utilisateurs par jour</p>
            <p style={{ width: 300, color: '#1c1', fontWeight: 'bold', fontSize: 25, marginLeft: 20 }}>+ {Math.round(this.state.usersRaise * 100)}% en 7 jours</p>
          </div>
          <ResponsiveLine
            margin={{
              bottom: 100,
              left: 100,
              top: 50,
              right: 150
            }}
            data={this.state.users}
            xScale={{ type: 'point' }}
            xFormat="time:%d/%m/%Y"
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
                orient: 'left',
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Inscriptions',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            colors={ [colors.green, colors.blue, colors.error] }
            pointSize={0}
            pointColor={{ theme: 'background' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            isInteractive={true}
            legends={[{
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 150,
              translateY: 0,
              itemWidth: 100,
              itemHeight: 20,
              itemsSpacing: 10,
              symbolSize: 12,
              symbolShape: 'circle',
              itemDirection: 'left-to-right',
              itemTextColor: '#777',
            }]}
          />
        </div>
      ) : (
        <h2>Chargement des utilisateurs...</h2>
      )}
      {this.state.orders ? (
        <div style={{ margin: 20, height: 400, width: '80%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2>Cours</h2>
            <p style={{ width: 300, color: '#11c', fontWeight: 'bold', fontSize: 20 }}>Moyenne sur 7 jours: {Math.round(10 * this.state.ordersAverage) / 10} cours par jour</p>
            <p style={{ width: 300, color: '#1c1', fontWeight: 'bold', fontSize: 25, marginLeft: 20 }}>+ {Math.round(this.state.ordersRaise * 100)}% en 7 jours</p>
          </div>
          <ResponsiveLine
            margin={{
              bottom: 100,
              left: 100,
              top: 50,
              right: 50
            }}
            data={this.state.orders}
            xScale={{ type: 'point' }}
            xFormat="time:%d/%m/%Y"
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
                orient: 'left',
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Cours',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            colors={ [colors.green, colors.blue, colors.error] }
            pointSize={0}
            pointColor={{ theme: 'background' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            isInteractive={true}
          />
        </div>
      ) : (
        <h2>Chargement des cours...</h2>
      )}
      {console.log('passes', this.state.passes)}
      {this.state.passes ? (
        <div style={{ margin: 20, height: 400, width: '80%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2>Oseille</h2>
            <p style={{ width: 300, color: '#11c', fontWeight: 'bold', fontSize: 20, marginLeft: 20 }}>Moyenne: {Math.round(300 * this.state.passesAverage) / 10} € / mois</p>
            <p style={{ width: 300, color: '#1c1', fontWeight: 'bold', fontSize: 25, marginLeft: 20 }}>+ {Math.round(this.state.passesRaise * 100)}% en 7 jours</p>
            <p style={{ width: 300, color: '#1c1', fontWeight: 'bold', fontSize: 25, marginLeft: 20 }}>CA Total: {this.state.totalCa}€</p>
            <p style={{ width: 300, color: '#1c1', fontWeight: 'bold', fontSize: 25, marginLeft: 20 }}>CA du mois: {this.state.monthCa}€</p>
          </div>
          <ResponsiveLine
            margin={{
              bottom: 100,
              left: 100,
              top: 50,
              right: 50
            }}
            data={this.state.passes}
            xScale={{ type: 'point' }}
            xFormat="time:%d/%m/%Y"
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
                orient: 'left',
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Pass',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            colors={ colors.button_blue }
            pointSize={0}
            pointColor={{ theme: 'background' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            isInteractive={true}
          />
        </div>
      ) : (
        <h2>Cueillette...</h2>
      )}
      {this.state.subscriptions ? (
        <div style={{ margin: 20, height: 400, width: '80%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2>Oseille</h2>
            <p style={{ width: 300, color: '#11c', fontWeight: 'bold', fontSize: 20, marginLeft: 20 }}>Moyenne: {Math.round(300 * this.state.subscriptionsAverage) / 10} € / mois</p>
            <p style={{ width: 300, color: '#1c1', fontWeight: 'bold', fontSize: 25, marginLeft: 20 }}>+ {Math.round(this.state.subscriptionsRaise * 100)}% en 7 jours</p>
            <p style={{ width: 300, color: '#1c1', fontWeight: 'bold', fontSize: 25, marginLeft: 20 }}>CA Total (Abos): {this.state.totalCaSub}€</p>
            <p style={{ width: 300, color: '#1c1', fontWeight: 'bold', fontSize: 25, marginLeft: 20 }}>CA du mois (Abos): {this.state.monthCaSub}€</p>
          </div>
          <ResponsiveLine
            margin={{
              bottom: 100,
              left: 100,
              top: 50,
              right: 50
            }}
            data={this.state.subscriptions}
            xScale={{ type: 'point' }}
            xFormat="time:%d/%m/%Y"
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
              orient: 'left',
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Pass',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            colors={ colors.button_blue }
            pointSize={0}
            pointColor={{ theme: 'background' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            isInteractive={true}
          />
        </div>
      ) : (
        <h2>Chargement des abonnements...</h2>
      )}
    </div>
  }
}

export default withRouter(Home);
