const URL = process.env.REACT_APP_API_URL;

let authHeader = localStorage.getItem('AUTH_HEADER', '');
const setAuthHeader = (newAuthHeader) => {
  authHeader = newAuthHeader;
};
const removeAuthHeader = () => {
  authHeader = '';
};

const get = async (path, params, headers) => {
  if (typeof headers === 'undefined') { headers = {}}
  headers['Authorization'] = authHeader
  headers['Content-Type'] = 'application/json'
  const queryString = params ? `?${Object.keys(params).map(key => `${key}=${params[key]}`).join('&')}` : ''
  console.log('queryString', queryString);
  try {
    const result = await fetch(`${URL}${path}${queryString}`, {
      method: 'GET',
      headers: headers,
    })
    const data = await result.json();
    return data;
  } catch(err) {
    console.error('Error getting', path, err);
    return null;
  }
}

const post = async (path, body, headers) => {
  if (typeof headers === 'undefined') { headers = {}}
  headers['Authorization'] = authHeader
  headers['Content-Type'] = 'application/json'
  try {
    const result = await fetch(`${URL}${path}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    })
    const data = await result.json();
    return data;
  } catch(err) {
    console.error('Error posting', path, err);
    return null;
  }
}

const patch = async (path, body, headers) => {
  if (typeof headers === 'undefined') { headers = {}}
  headers['Authorization'] = authHeader
  headers['Content-Type'] = 'application/json'
  try {
    const result = await fetch(`${URL}${path}`, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(body),
    })
    const data = await result.json();
    return data;
  } catch(err) {
    console.error('Error posting', path, err);
    return null;
  }
}

const _delete = async (path, body, headers) => {
  if (typeof headers === 'undefined') { headers = {}}
  headers['Authorization'] = authHeader
  headers['Content-Type'] = 'application/json'
  try {
    const result = await fetch(`${URL}${path}`, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(body),
    })
    const data = await result.json();
    return data;
  } catch(err) {
    console.error('Error deleting', path, err);
    return null;
  }
}

const download = async (url, headers) => {
  if (typeof headers === 'undefined') { headers = {}}
  headers['Authorization'] = authHeader
  headers['Content-Type'] = 'application/pdf'
  try {
    console.log('fetching url', url);
    const result = await fetch(url, {
      method: 'GET',
      headers: headers,
    })
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch(err) {
    console.error('Error downloading', url, err);
    return null;
  }
}


export {
  get,
  post,
  patch,
  _delete,
  setAuthHeader,
  removeAuthHeader,
  download,
};
