export default {
  dark_blue: '#002d6d',
  button_blue: '#0d4ed9',
  blue: '#4080ff',
  bg: '#f5f5f7',
  error: '#e02d2d',
  orange: '#ff962e',
  yellow: '#ffc700',
  green: '#35b773',
  gray: '#939393',
  validated: '#23d4ac',
}
