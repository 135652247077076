import React, { Component, useState, useEffect, useMemo, useCallback } from 'react';
import { getColor } from '../utils';
import { get } from '../services/api';
import PowerfulTable from './PowerfulTable/PowerfulTable';
import Loading from './Loading/Loading';

export default function OrdersList(props) {
  const [orders, setOrders] = useState(props.orders || null);
  const [filteredOrders, setFilteredOrders] = useState(props.orders || null);
  const [filter, setFilter] = useState('');
  const [showCancelled, setShowCancelled] = useState(false);
  const [showAll, setShowAll] = useState(false);

  // eslint-disable-next-line
  const fetchOrders = async () => {
    const orders = await get(`/admin/orders`);
    setOrders(orders)
    setFilteredOrders(orders);
    setFilter('');
  };

  useEffect(() => {
    if (!props.orders) {
      fetchOrders();
    } else {
      setOrders(props.orders);
      setFilteredOrders(props.orders);
    }
  }, [props.orders]); // eslint-disable-line

  useEffect(() => {
    setFilter('');
    if (props.orders) {
      setOrders(props.orders);
    } else {
      fetchOrders();
    }
  }, [props.orders]); // eslint-disable-line

  const filterOrders = () => {
    if (!orders) return;
    const s = filter.toLowerCase()
    const filterFctn = (o) => (
      o._id.toLowerCase().includes(s)
      || o.topic.name.toLowerCase().includes(s)
      || o.level.name.toLowerCase().includes(s)
      || o.status.toLowerCase().includes(s)
      || o.createdBy.toLowerCase().includes(s)
      || o.teacher?.toLowerCase().includes(s)
      || (new Date(o.date)).toLocaleString().toLowerCase().includes(s)
      || (new Date(o.createdOn)).toLocaleString().toLowerCase().includes(s)
    );
    setFilteredOrders(orders.filter(filterFctn));
  }

  useEffect(filterOrders, [ filter ]);

  const prettyDuration = (duration) => {
    switch(duration) {
      case 1:
        return '1h00'
      case 1.5:
        return '1h30'
      case 2:
        return '2h00'
      default:
        return '1h00'
    }
  }

  const in1Month = useMemo(() => {
    const d = new Date()
    d.setMonth(d.getMonth() + 1);
    return d;
  }, []);

  const data = useMemo(() => {
    return filteredOrders?.filter((o) => (
      (new Date(o.date) < in1Month || showAll)
      && (o.status !== 'cancelled' || showCancelled))
    );
  }, [filteredOrders, in1Month, showAll, showCancelled])

  if (!filteredOrders) return <Loading/>;
  return (
    <div>
      <div style={{ marginLeft: 20 }}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <h2>Cours ({filteredOrders.length})</h2>
          <div>
            <label style={{marginRight: 5}}>Afficher les cours annulés</label>
            <input type='checkbox' value={showCancelled} onChange={(e) => setShowCancelled(e.target.checked)} />
          </div>
          <div>
            <label style={{marginRight: 5}}>Afficher tous les cours</label>
            <input type='checkbox' value={showAll} onChange={(e) => setShowAll(e.target.checked)} />
          </div>
          <div style={{marginRight: 50}}>
            <input
              placeholder='Filtrer...'
              type='text'
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
              style={{ paddingLeft: 10, width: 180, height: 30, borderRadius: 30, boxShadow: 'none', borderStyle: 'hidden', backgroundColor: '#eee', outline: 'none' }}
            />
          </div>
        </div>

        {data.length > 0 && <PowerfulTable
          data={data}
          types={{
            _id: { name: 'id' },
            status: { name: 'statut' },
            topic: { name: 'matière' },
            level: { name: 'niveau' },
            date: { name: 'date' },
            duration: { name: 'durée' },
            createdOn: { name: 'commandé le' },
            createdBy: { name: 'élève' },
            teacher: { name: 'professeur' },
            newOrder: { name: 'Nouveau' },
          }}
          redirect={'orders'}
          redirectKey={'_id'}
        />}
        {data.length === 0 && orders.length > 0 && <p style={{ width: '100%', textAlign: 'center' }}>Aucun cours dans le prochain mois (essayer de cocher 'Afficher tous les cours')</p>}
        {orders.length === 0 && <p style={{ width: '100%', textAlign: 'center' }}>Aucun cours</p>}
      </div>
    </div>
  );
}
