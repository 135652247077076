import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthContext from '../contexts/AuthContext';

export default ({ component: Component, ...props }) => {

  const authContext = useContext(AuthContext);
  console.log('authContext', authContext)

  return (
    <Route
      {...props}
      render={props =>
        authContext.authHeader ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}
