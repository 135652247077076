import React, { useState, useEffect } from 'react';

import { get } from '../services/api';
import Loading from './Loading/Loading';
import PowerfulTable from './PowerfulTable/PowerfulTable';

const TeachersList = (props) => {

  const [ teachers, setTeachers ] = useState(null);
  const [ filteredTeachers, setFilteredTeachers ] = useState(null);
  const [ filter, setFilter ] = useState('');
  const [ topics, setTopics ] = useState(null);

  useEffect(() => {
    fetchTeachers()
    fetchTopics()
  }, [props.teachers]);

  const fetchTeachers = async () => {
    const teachers = await get('/admin/teachers?limit=1000');
    setTeachers(teachers);
    setFilteredTeachers(teachers);
    setFilter('');
  }

  const fetchTopics = async () => {
    const topics = await get('/public/topics');
    setTopics(topics);
  }

  const filterTeachers = async () => {
    if (!topics || !teachers) return;
    const s = filter.toLowerCase()
    const matchingTopics = topics.filter(t => t.name.toLowerCase().includes(s)).map(t => (t._id))
    const filteredTeachers = teachers.filter((p) => {
      if (p.topics && p.topics.filter(topic => matchingTopics.indexOf(topic._id) >= 0).length > 0) {
        return true
      }
      return (p._id.toLowerCase().includes(s) ||
      p.firstname.toLowerCase().includes(s) ||
      p.lastname.toLowerCase().includes(s) ||
      (p.number && p.number.toLowerCase().includes(s)) ||
      (new Date(p.signUp ? p.signUp.timestamp : p.createdAt)).toLocaleString().toLowerCase().includes(s))
    })
    setFilteredTeachers(filteredTeachers);
  }

  useEffect(() => {
    filterTeachers();
  }, [filter]); // eslint-disable-line

  if (!topics || !filteredTeachers) return <Loading />

  return (
    <div style={{ marginLeft: 20, width: '100%', height: '100%' }}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>Professeurs ({teachers.length})</h2>
        <div style={{marginRight: 50}}>
          <input
            placeholder='Rechercher...'
            type='text'
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
            style={{ paddingLeft: 10, width: 180, height: 30, borderRadius: 30, boxShadow: 'none', borderStyle: 'hidden', backgroundColor: '#eee', outline: 'none' }}
          />
        </div>
      </div>

      <PowerfulTable
        data={filteredTeachers}
        types={{
          _id: { name: 'email' },
          firstname: { name: 'prénom' },
          lastname: { name: 'nom' },
          number: { name: 'téléphone' },
          pictureValidated: { name: 'photo validée' },
          filledCv: { name: 'CV rempli' },
          rate: { name: 'note' },
          signup: { name: 'inscription' },
        }}
        redirect={'teachers'}
        redirectKey={'_id'}
      />
    </div>
  );
};

export default TeachersList;
