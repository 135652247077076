import PouchDB from 'pouchdb';
import Find from 'pouchdb-find';

PouchDB.plugin(Find);

let db = new PouchDB(`https://db.expliquemoipythagore.fr/pythagore-shared`, {
  auth: {
    username: "api",
    password: "WmtGLXVEbgLK5pExgpNqbN5Ax23sgZS4"
  }
});


const login = async (username, password) => {
  db = new PouchDB(`https://db.expliquemoipythagore.fr/pythagore-shared`, {
    auth: {
      username: "api",
      password: "WmtGLXVEbgLK5pExgpNqbN5Ax23sgZS4"
    }
  });
  console.log('Database inited :', db)
  await db.info()
  await db.createIndex({ index: { fields: ['date'] } })
  localStorage.setItem('username', username)
  localStorage.setItem('password', password)
}

const get = async (req, options = {}) => {
  return db.get(req, options)
}

const find = async (req) => {
  return db.find(req)
}

const post = async (req) => {
  return db.post(req)
}

if (localStorage.getItem('username') && localStorage.getItem('password')) {
  login(localStorage.getItem('username'), localStorage.getItem('password'))
}

export { login, find, get, post };
