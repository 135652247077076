import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { get } from '../services/api';
import Loading from './Loading/Loading';
import PowerfulTable from './PowerfulTable/PowerfulTable';

const URL = process.env.REACT_APP_API_URL;

export default class SubscriptionsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subscriptions: props.subscriptions || undefined,
      filteredSubscriptions: props.subscriptions || undefined,
      filter: '',
      showCreated: false
    };
  }

  componentDidMount() {
    if (!this.props.subscriptions) {
      this.fetchSubscriptions();
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({ filter: '' })
    if (newProps.subscriptions) {
      this.setState({subscriptions: newProps.subscriptions, filteredSubscriptions: newProps.subscriptions, filter: ''})
    } else {
      this.fetchSubscriptions()
    }
  }

  fetchSubscriptions = async () => {
    let subscriptions = await get(`/admin/subscriptions?limit=1000`);
    subscriptions = subscriptions.map(s => ({
      ...s,
      bill: `${URL}/admin/subscriptions/${s._id}/bill`,
    }))
    this.setState({ subscriptions, filteredSubscriptions: subscriptions, filter: '' });
  }

  filter = (event) => {
    const s = event.target.value.toLowerCase()
    let filteredSubscriptions = this.state.subscriptions.filter((p) => {
      return (
        p._id.toLowerCase().includes(s)
        || p.user.toLowerCase().includes(s)
        || p.status.toLowerCase().includes(s)
        || (p.ref || p.refnr)?.toLowerCase()?.includes(s)
        || (p.id || p.TransID).toLowerCase().includes(s)
        || (p.amount / 100).toString().toLowerCase().includes(s)
        || moment(new Date(p.createdOn)).format('dd DD-MM-YYYY DD/MM/YYYY à HH[h]mm').toLowerCase().includes(s))
    })
    this.setState({ filter: event.target.value, filteredSubscriptions })
  }

  render() {
    if (!this.state.filteredSubscriptions) return <Loading />
    return  <div style={{ marginLeft: 20 }}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>Abonnements ({this.state.filteredSubscriptions.filter(p => (p.status !== 'created' || this.state.showCreated)).length})</h2>
        <div>
          <label style={{marginRight: 5}}>Afficher les achats en cours</label>
          <input type='checkbox' value={this.state.showCreated} onChange={(e) => this.setState({ showCreated: e.target.checked })} />
        </div>
        <div style={{marginRight: 50}}>
          <input placeholder='Filtrer...' type='text' onChange={this.filter} value={this.state.filter}
            style={{ paddingLeft: 10, width: 180, height: 30, borderRadius: 30, boxShadow: 'none', borderStyle: 'hidden', backgroundColor: '#eee', outline: 'none' }}
          />
        </div>
      </div>

      <PowerfulTable
        data={this.state.filteredSubscriptions.filter(p => (p.status !== 'created' || this.state.showCreated))}
        types={{
          _id: { name: 'id' },
          user: { name: 'élève' },
          amount: { name: 'montant (€)' },
          status: { name: 'statut' },
          id: { name: 'id banque' },
          ref: { name: 'réf. banque' },
          renewal: { name: 'renouvellement' },
          createdOn: { name: 'date' },
          bill: { name: 'Facture' },
        }}
        redirect={'subscriptions'}
        redirectKey={'_id'}
      />

    </div>
  }
}
